/* global Cookiebot, _paq */
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

exports.onClientEntry = () => {
  if (!sessionStorage.getItem("ak-forwarded")) {
    let val = "direct"
    if (document.referrer.length > 0) {
      val = document.referrer
    }
    sessionStorage.setItem("ak-forwarded", val)
  }

  var waitForTrackerCount = 0;
  function matomoWaitForTracker() {
    if (typeof _paq === 'undefined' || typeof Cookiebot === 'undefined') {
      if (waitForTrackerCount < 40) {
        setTimeout(matomoWaitForTracker, 250);
        waitForTrackerCount++;
        return;
      }
    } else {
      window.addEventListener('CookiebotOnAccept', function (_e) {
        consentSet();
      });
      window.addEventListener('CookiebotOnDecline', function (_e) {
        consentSet();
      })
    }
  }
  function consentSet() {
    if (Cookiebot.consent.statistics) {
      _paq.push(['rememberCookieConsentGiven']);
      _paq.push(['setConsentGiven']);
    } else {
      _paq.push(['forgetCookieConsentGiven']);
      _paq.push(['deleteCookies']);
    }
  }
  document.addEventListener('DOMContentLoaded', matomoWaitForTracker);
}
