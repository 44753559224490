module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.auslandsjahr.org"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"auslandsjahr.org","short_name":"auslandsjahr.org","start_url":"/","background_color":"#ffffff","theme_color":"#e96101","display":"standalone","icon":"src/images/auslandsjahr-emblem.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"677b649638c25019302fef387819ab80"},
    },{
      plugin: require('../node_modules/gatsby-source-akcms-v2/gatsby-browser.js'),
      options: {"plugins":[],"apiToken":"3d1eaa33925e4a1cf7ac6d5b4e7d7dd7423f5ed05756360b","apiBaseUrl":"https://api.cms.austauschkompass.de/api/v1"},
    },{
      plugin: require('../node_modules/gatsby-source-akratings-high-school/gatsby-browser.js'),
      options: {"plugins":[],"apiUrl":"https://www.schueleraustausch.de/api/v2"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-W53CZH4B","includeInDevelopment":true,"enableWebVitalsTracking":true,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"13","matomoUrl":"https://stats.schueleraustausch.net","siteUrl":"https://www.auslandsjahr.org","requireCookieConsent":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
